<template>
	<div class="wrap">
		<div class="cont">
			<el-form label-width="150px"  ref="form" v-model="form">
				<div class="tex">
					<div class="one">我们将在<span id="time">1-3个工作日内</span>完成审核，并通过短信的方式将结果通知到你</div>
					<div class="two">中国农业银行会在<span id="time">1-3个工作日内</span>向收款方：<span class="green">{{form.AccountName}}</span>转入小额随机金额，以做账户确认，收到款项时，请在下列输入框内输入收款金额，提交验证</div>
				</div>
				<div class="tabb">
					<div class="onebox">
						<table border="1" cellspacing="0" cellpadding="0">
							<tr>
								<th>收款方开户行：</th>
								<td class="khh">{{form.BankName}}</td>
							</tr>
							<tr>
								<th>收款方账户名称：</th>
								<td class="zhname">{{form.AccountName}}</td>
							</tr>
							<tr>
								<th>收款方账号：</th>
								<td class="zhzh">{{form.Account}}</td>
							</tr>
						</table>
							<div class="tex red">*请确认以上银行信息是否正确完整,不要相信以认证为借口要求你转账到其他账户的信息。</div>
					</div>
					
						<div class="bbot">
							<span class="StepRemark"></span>
							<div class="moen" style="display: none;">
								<el-form-item  class="money" label="收款金额" prop="money">
									<el-input v-model="form.amount" placeholder="收款金额" />
								</el-form-item>
							</div>
							
							
						</div>
				</div>
				<div class="xiugai">
					<el-button class="fh" prop="money" @click="xiugai">返回上一步</el-button>
				</div>
				<div class="btn_box">
					<el-button type="primary" class="btnn" @click="handleSubmit">提交</el-button>
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default{
		data() {
			return{
				checked:'',
				time:'',
				form:{
					amount:'',
					BankName:'',
					AccountName:'',
					Account:'',
				}
			}
		},
		created() {
			this.gethome()
		},
		methods:{
			xiugai(){
				this.$router.push('/auszahlung/trading');
			},
			handleSubmit(){
				this.$axios.post('/vertyCode', {
					seller_id: this.$store.state.SELLER_ID,
					amount:this.form.amount
				}).then(data => {
					if(data.ReturnCode == '0000'){
						this.$message.success(data.ReturnMsg)
						window.location.reload()
					}else{
						this.$message.error(data.ReturnMsg)
					}
				})
			},
			gethome(){
				this.$axios.get('vertyCode?seller_id='+this.$store.state.SELLER_ID).then(data => {
					this.form = data
					if(data.isPassed== 9){
						$(".two").hide()
						$(".one").hide()
						$(".onebox").hide()
						$(".moen").hide()
						$(".btn_box").hide()
						$(".xiugai").show()
						$(".StepRemark").html('审核结果：'+data.StepRemark)
						// $(".StepRemark").html('审核结果：审核失败，点击下方按钮重新提交')
					}else if(data.step == 0){
						$(".one").hide()
						$(".moen").show()
						$(".btn_box").show()
						$(".xiugai").hide()
					}else if(data.step == 1){
						$(".two").hide()
						$(".onebox").hide()
						$(".moen").hide()
						$(".btn_box").hide()
						$(".xiugai").hide()
						$(".StepRemark").html("正在审核~")
					}else if(data.step == 2){
						this.$router.push({
							path: '/user/4',
							query: {
								BankName: this.form.BankName,
								Account: this.form.Account,	
							}
						})
					}else if(data.step == 3){
						$(".two").hide()
						$(".one").hide()
						$(".onebox").hide()
						$(".moen").hide()
						$(".btn_box").hide()
						$(".xiugai").show()
						$(".StepRemark").html('审核结果：'+data.StepRemark)
						// $(".StepRemark").html('审核结果：审核失败，点击下方按钮重新提交')
					}
				})
			}
			
		}
		
	}
</script>

<style scoped>
	.wrap{
		width: 1600px;
		background-color: #fff;
	}
	.tj{
		width: 112px;
	}
	.xiugai{
		margin-top: 20px;
		display: flex;
		justify-content: center;
	}
	.cont{
		width: 700px;
		margin: 0 0 0 200px;
		padding-top: 80px;
	}
	.tex{
		/* text-align: center;	 */
		margin-top: 30px;
		font-size: 14px;
	}
	.tex .one,.tex .two{
		height: 40px;
		line-height: 40px;
	}
	.green{
		color: #33BA49;
	}
	.red{
		color: red;
	}
	table{
		/* margin: 20px auto; */
		margin-top: 20px;
	}
	table>tr>th{
		width: 205px;
		height: 50px;
	}
	table>tr>td{
		width: 405px;
		height: 50px;
	}
	.el-form-item>>>.el-input{
		width: 235px;
	}
	.money{
		font-size: 20px;
		font-weight: bold;
		margin-left: 80px;
	}
	.money el-input{
		display: block;
	}
	.btnn,.next{
		width: 155px;
		height: 40px;
		margin-top: 30px;
	}
	.bbot{
		width: 700px;
		margin: 20px auto;
		margin-top: 30px;
	}
	.bot{
		display: flex;
		justify-content: center;
		
	}
	.btn_box,.btn_box2{
		display: flex;
		justify-content: center;
	}
	.bottom{
		margin-top: 150px;
	}
	#time{
		color: #33BA49;
	}
	.zhname,.zhzh,.khh{
		text-indent:1em
	}
	.StepRemark{
		font-size: 18px;
		color: red;
		display: block;
		margin: 0  0 20px 0;
	}
	.tabb{
		margin-top: 50px;
	}
	.xiugai{
		
	}
</style>
